import { Box, Stack } from '@mui/material';
import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

const NotFoundPage = () => {
  // const siteTitle = data.site.siteMetadata.title;

  return (
    <>
      <Seo title="404: Not Found" />
      <Layout>
        <Box
          height={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Stack spacing={5} alignItems="center">
            <img src="/logo.png" />
            <img src="/Optipass-text.png" />
            <h1>404: Not Found</h1>
          </Stack>
        </Box>
      </Layout>
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
